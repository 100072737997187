import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const toastOptions = {
  position: "bottom-right",
  autoClose: 4000,
  pauseOnHover: true,
  draggable: true,
  theme: "dark",
};

const handleSubmit = () => {
  toast.error("Username or password is incorrect.", toastOptions);
  return false;
};
function Login() {
  return (
    <>
      <div className="flex flex-col m-16">
        <div className="flex flex-col mx-auto lg:w-1/3">
          <img
            src="../dark_logo_transparent_background.png"
            className="w-24 mx-auto"
          />
          <div className="mx-auto mt-12 w-full flex flex-col">
            <h2 className="text-4xl mt-8 mx-auto">Client Login</h2>
            <input
              type="text"
              placeholder="Client Username"
              className="input input-bordered w-full max-w-xs mx-auto mt-8 placeholder:text-black"
            />
            <input
              type="password"
              placeholder="Client Password"
              className="input input-bordered w-full max-w-xs mx-auto mt-8 placeholder:text-black"
            />
            <button
              onClick={() => handleSubmit()}
              className="btn btn-rounded mt-12 w-1/2 mx-auto text-white rounded-3xl"
            >
              Login
            </button>
            <a
              className="mx-auto mt-8 underline cursor-pointer"
              href="http://quinncomputing.com"
            >
              <span>Not a client? Check out our services and contact us.</span>
            </a>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Login;
